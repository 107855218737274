import EventBus from '@/adready-vue/helpers/global/event-bus';
import advertiserReportsAPI from '@/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';

const computed = {};

const methods = {
  setLoaderStatus() {
    if (!this.userHasAiAssistant && !this.loading) {
      this.loading = true;
    } else if (!this.userHasAiAssistant && this.loading) {
      this.loading = false;
    }
  },
  async loadPerformancePublisherData(payload) {
    this.setLoaderStatus();
    payload = { ...payload };
    payload.groupBy = 'publisher';
    payload = { ...payload };
    try {
      const dataValidated = this.validateParams(payload, this.advertiser);
      let result = [];
      let incReachMetricsData = {};
      if (dataValidated) {
        result = await advertiserReportsAPI.performance(
          this.advertiser.id,
          buildQueryString(payload)
        );
        if (this.selectedEvent?.isIncrementalReach) {
          incReachMetricsData = await advertiserReportsAPI.incremental_reach_metrics(
            this.advertiser.id,
            buildQueryString(payload)
          );
          this.$store.dispatch('dashboard/setIncReachMetric', incReachMetricsData);
        }

        result = result.map((item) => {
          return {
            ...item,
            incrementalReachPercentage: {
              change: item?.ireachPercentage?.change,
              compareValue: item?.ireachPercentage?.compareValue,
              currentValue: item?.ireachPercentage?.currentValue || 0,
              isChangeInfinity: item?.ireachPercentage?.isChangeInfinity || false,
              isCurrentInfinity: item?.ireachPercentage?.isCurrentInfinity || false,
            },
          };
        });
      }
      this.$store.set('dashboard/performance@publisher', result || []);
    } catch (err) {
      console.error('error fetching Publishers Performance ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    } finally {
      this.setLoaderStatus();
    }
  },
  async loadPerformanceAudienceData(payload) {
    this.setLoaderStatus();
    payload = { ...payload };
    payload.groupBy = 'audience';

    try {
      const dataValidated = this.validateParams(payload, this.advertiser);
      let result = [];
      if (dataValidated) {
        result = await advertiserReportsAPI.performance(
          this.advertiser.id,
          buildQueryString(payload)
        );
      }
      this.$store.set('dashboard/performance@audience', result || []);
    } catch (err) {
      console.error('error fetching Audiences Performance ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    } finally {
      this.setLoaderStatus();
    }
  },
  async loadPerformanceCreativeData(payload) {
    this.setLoaderStatus();
    payload = { ...payload };
    payload.groupBy = 'creative';

    try {
      const dataValidated = this.validateParams(payload, this.advertiser);
      let result = [];
      if (dataValidated) {
        result = await advertiserReportsAPI.performance(
          this.advertiser.id,
          buildQueryString(payload)
        );
      }
      this.$store.set('dashboard/performance@creatives', result || []);
    } catch (err) {
      console.error('error fetching Creatives Performance ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    } finally {
      this.setLoaderStatus();
    }
  },
  async loadPerformanceMediaTypeData(payload) {
    this.setLoaderStatus();
    payload = { ...payload };
    payload.groupBy = 'mediatype';

    try {
      const dataValidated = this.validateParams(payload, this.advertiser);
      let result = [];
      if (dataValidated) {
        result = await advertiserReportsAPI.performance(
          this.advertiser.id,
          buildQueryString(payload)
        );
      }
      this.$store.set('dashboard/performance@mediaType', result || []);
    } catch (err) {
      console.error('error fetching MediaTypes Performance ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    } finally {
      this.setLoaderStatus();
    }
  },
  async loadPerformanceAdGroupData(payload) {
    this.setLoaderStatus();
    payload = { ...payload };
    payload.groupBy = 'adgroup';

    try {
      const dataValidated = this.validateParams(payload, this.advertiser);
      let result = [];
      if (dataValidated) {
        result = await advertiserReportsAPI.performance(
          this.advertiser.id,
          buildQueryString(payload)
        );
      }
      this.$store.set('dashboard/performance@adGroup', result || []);
    } catch (err) {
      console.error('error fetching AdGroups Performance ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    } finally {
      this.setLoaderStatus();
    }
  },
  async loadAllPerformanceData(payload) {
    this.loading = true;
    try {
      await Promise.all([
        this.loadPerformancePublisherData(payload),
        this.loadPerformanceMediaTypeData(payload),
        this.loadPerformanceCreativeData(payload),
        this.loadPerformanceAudienceData(payload),
        this.loadPerformanceAdGroupData(payload),
      ]);

      // Set the flag to true after all promises resolve
      EventBus.performanceAPILoadedOnAIEnabled = true;
    } catch (error) {
      // Handle any errors that may have occurred during the execution of any of the above functions
      console.error('An error occurred:', error);
    } finally {
      this.loading = false;
    }
  },
};

export default { methods, computed };
